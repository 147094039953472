
import React, { useEffect } from 'react';
import './HomePageTwo.scss';

export default function HomePageTwo(props) {
  const { moveStatus, pageTabActive, leaveAnimation, isSmallScreen } = props;

  useEffect(() => {
    // if(pageTabActive === 1 && action === 'up') {
    // 	setTimeout(() => {
    // 		setState({
    // 			hasBg: true
    // 		})
    // 	}, 1000)
    // }else if(pageTabActive === 0 && action === 'down') {
    // 	setTimeout(() => {
    // 		setState({
    // 			hasBg: true
    // 		})
    // 	}, 1000)
    // } else if(pageTabActive === 2 && action === 'down') {
    // 	setTimeout(() => {
    // 		setState({
    // 			hasBg: true
    // 		})
    // 	}, 1000)
    // } else {
    // 	setState({
    // 		hasBg: false
    // 	})
    // }

  }, [pageTabActive]);
  // ${pageTabActive === 2  && 'translate'} ${step === 1 && 'show'} ${state.operate === 'down' && 'hidden'}

  return <div
    className={`home-content-two ${isSmallScreen && 'home-content-two-m'}`}
    id="temp2"
    style={{
      background: `${isSmallScreen || ((pageTabActive === 1) && !moveStatus) ? 'url(https://oss.5rs.me/oss/uploadfe/png/0ce32552a7263b209c02aeac19de8aad.png) no-repeat center center/19.2rem' : ''}`
    }}
  >
    <div className="home-content-two-container">
      <div className={`big ${(isSmallScreen || ((pageTabActive === 1) && !moveStatus)) && 'show'} ${!isSmallScreen && (pageTabActive === 0) && !moveStatus && 'hidden'} ${!isSmallScreen && pageTabActive > 1 && leaveAnimation && 'translate'}`}>
        <div className="big-rotate" />
        <div className="big-text">
          <div className="big-text-logo mt10">
            <img src="https://oss.5rs.me/oss/uploadfe/png/60eaa140dfaf610cba0aef60e1c3fe48.png" />
          </div>
          <div className="big-text-text mt10">
            <div className="text-bg-wrapper">
              <p>数传集团是全球可信赖的出版融合数字服务合作伙伴，</p>
              <p>致力于用数字化助力出版行业转型升级，依托信息内容</p>
              <p>与知识服务搭建全新运营模式，探索“线下+线上”、</p>
              <p>“阅读+体验”的综合消费模式，构建全民数字化</p>
              <p>阅读平台及“可持续更美好”的出版未来。</p>
            </div>
          </div>
        </div>
        <div className="small">
          <div className="rotate" />
          <div className="about">
            <img src="https://oss.5rs.me/oss/uploadfe/png/ef9efb77f570e7b8678684e9f3af775a.png" />
          </div>
        </div>
      </div>
    </div>
  </div>;
}