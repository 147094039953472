import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSetState, useUpdateEffect } from 'ahooks';
import pointJson from './ponit.json';
import './AnimationPage.scss';

export default function AnimationPage(props) {
  const { pageTabActive, onChangePageTab, action, setMoveStatus, enterAnimation, isClick } = props;
  const interval = useRef(null);
  const [state, setState] = useSetState({
    operate: 'up',
    show: false,
    y: -685,
    x: 540,
    r: 644.5, //400 //820 1222
    by: 37,
    bOpacity: 1,
    bx: -245,
    bw: 269,
    cx: 610,
    cy: 486,
    dx: 549,
    dy: 180,
    dw: 36
  });

  const useSyncCallback = callback => {
    const [proxyState, setProxyState] = useState({ current: false });

    const Func = useCallback(() => {
      setProxyState({ current: true });
    }, [proxyState]);

    useEffect(() => {
      if(proxyState.current === true) setProxyState({ current: false });
    }, [proxyState]);

    useEffect(() => {
      proxyState.current && callback();
    });

    return Func;
  };

  const startAnimation = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[0]] : [...pointJson[0]].reverse();

    setTimeout(() => {
      setMoveStatus(true);
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.r === 374.5 && action === 'up') {
            setTimeout(() => {
              onChangePageTab(1);
            }, 1000);
          }
          if(item.r === 554.5 && action === 'down') {
            onChangePageTab(0);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
      }, 20);
    }, action === 'down' ? 780 : 0);
  });

  const startAnimation2 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[1]] : [...pointJson[1]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.r === 465 && action === 'up') {
            setTimeout(() => {
              onChangePageTab(2);
            }, 400);
          }
          if(item.r === 365 && action === 'down') {
            onChangePageTab(1);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'up' ? 1000 : 600);
  });

  const startAnimation3 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[2]] : [...pointJson[2]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.x === -685.5 && action === 'up') {
            onChangePageTab(3);
          }
          if(item.x === -695 && action === 'down') {
            setTimeout(() => {
              onChangePageTab(2);
            }, 1500);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'up' ? 800 : 600);
  });

  const startAnimation4 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[3]] : [...pointJson[3]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.r === 3257 && action === 'up') {
            onChangePageTab(4);
          }
          if(item.r === 3167 && action === 'down') {
            setTimeout(() => {
              onChangePageTab(3);
            }, 1000);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'up' ? 800 : 600);
  });

  const startAnimation5 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[4]] : [...pointJson[4]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.y === 520 && action === 'up') {
            onChangePageTab(5);
          }
          if(item.y === 460 && action === 'down') {
            setTimeout(() => {
              onChangePageTab(4);
            }, 800);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'up' ? 300 : 600);
  });

  const startAnimation6 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[5]] : [...pointJson[5]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.y === -2000 && action === 'up') {
            onChangePageTab(6);
          }
          if(item.y === 430 && action === 'down') {
            onChangePageTab(5);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'up' ? 600 : 0);
  });

  const startAnimation7 = useSyncCallback(() => {
    let index = -1;
    let list = action === 'up' ? [...pointJson[6]] : [...pointJson[6]].reverse();
    setMoveStatus(true);
    setTimeout(() => {
      if(interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        index = index + 1;
        let item = list[index];
        if(item) {
          if(item.y === -6350 && action === 'up') {
            onChangePageTab(7);
          }
          if(item.y === -6275 && action === 'down') {
            setTimeout(() => {
              onChangePageTab(6);
            }, 500);
          }
          setState({
            ...item
          });
        } else {
          clearInterval(interval.current);
          interval.current = null;
          setMoveStatus(false);
        }
        return;
      }, 20);
    }, action === 'down' ? 500 : 0);
  });

  useUpdateEffect(() => {
    if(isClick) {
      let item = pointJson[pageTabActive][0];
      setState({
        ...item
      });
      return;
    }
    if((pageTabActive === 1 && action === 'up') || (pageTabActive === 0 && action === 'down')) {
      startAnimation();
    }
    if((pageTabActive === 2 && action === 'up') || (pageTabActive === 1 && action === 'down')) {
      startAnimation2();
    }
    if((pageTabActive === 3 && action === 'up') || (pageTabActive === 2 && action === 'down')) {
      startAnimation3();
    }
    if((pageTabActive === 4 && action === 'up') || (pageTabActive === 3 && action === 'down')) {
      startAnimation4();
    }
    if((pageTabActive === 5 && action === 'up') || (pageTabActive === 4 && action === 'down')) {
      startAnimation5();
    }
    if((pageTabActive === 6 && action === 'up') || (pageTabActive === 5 && action === 'down')) {
      startAnimation6();
    }
    if((pageTabActive === 7 && action === 'up') || pageTabActive === 6 && action === 'down') {
      startAnimation7();
    }
    return () => {
      interval.current && clearInterval(interval.current);
      interval.current = null;
    };
  }, [pageTabActive]);

  // ${(step === 1 || (step === 0 && state.operate ==='down')) && 'hidden'}
  return <div
    className={`move-animation-page`}
    style={{
      background: `${(pageTabActive === 1) && enterAnimation ? 'url(https://oss.5rs.me/oss/uploadfe/png/0ce32552a7263b209c02aeac19de8aad.png) no-repeat center center/19.2rem' : ''}`
    }}
  >
    <div className="move-animation-content">
      {/* <div
        className={`main-item ${state.show && 'translate'}`}
        style={{
          transform: `translate3d(${state.x / 100 }rem, ${state.y /100 }rem, 0)`,
          width: `${state.r * 2 /100 }rem`,
          height: `${state.r * 2 /100 }rem`
        }}
      /> */}
      <div
        className={`main-item ${pageTabActive === 0 && enterAnimation && 'translate'} ${action === 'down' && 'down'}`}
        style={{
          transform: `translate3d(${state.x / 100}rem, ${state.y / 100}rem, 0)`,
          background: `linear-gradient(to ${pageTabActive > 2 ? 'right' : 'left'},#124089,#2082C1)`,
          width: `${state.r * 2 / 100}rem`,
          height: `${state.r * 2 / 100}rem`
        }}
      />
      <div
        className={`item-left`}
        style={{
          // transform: `translate3d(${state.x / 100 }rem, ${state.y /100 }rem, 0)`,
          left: `${state.bx / 100}rem`,
          bottom: `${state.by / 100}rem`,
          opacity: state.bOpacity,
          width: `${state.bw / 100}rem`,
          height: `${state.bw / 100}rem`
        }}
      />
      <div
        className="item-right"
        style={{
          transform: `translate3d(${state.cx / 100}rem, ${state.cy / 100}rem, 0)`
        }}
      >
        <div className={`count-text transition-right`} />
      </div>
      {/* */}
      { pageTabActive < 2 && <div
        className="item-center"
        style={{
          transform: `translate3d(${state.dx / 100}rem, ${state.dy / 100}rem, 0)`,
          width: `${state.dw / 100}rem`,
          height: `${state.dw / 100}rem`
        }}
      />}
    </div>
  </div>;
}