import React from 'react';
import './HomePageOne.scss';
export default function HomePageOne(props) {
  const { enterAnimation, action, pageTabActive, isSmallScreen } = props;
  return <div className={`home-content-one ${isSmallScreen && 'home-content-one-m'} ${enterAnimation && (isSmallScreen || pageTabActive === 0) && 'enterAnimation'}`}>
    <div className="home-content-one-container">
      <div className={`text transition-left ${!isSmallScreen && pageTabActive > 0 && 'translate'}`}>
        <div className="mt10">
          <img src="https://oss.5rs.me/oss/uploadfe/png/d1378589427d136e6eec2d3009c683a3.png" />
        </div>
        <div className="topics">
          <div className="mt20">
            <span className="point" />
            <span>聚焦数字化创新</span>
          </div>
          <div className="mt20">
            <span className="point" />
            <span>助力出版深度融合</span>
          </div>
          <div className="mt30">
            <span className="point" />
            <span>重塑阅读生态新价值</span>
          </div>
        </div>
      </div>
      {isSmallScreen && (<><div className="item-left" /><div className="item-center" /></>)}
      <div className={`phone fr10 transition-right ${action === 'down' && 'down'} ${!isSmallScreen && pageTabActive > 0 && 'translate'}`}>
        <img src="https://oss.5rs.me/oss/uploadfe/png/4f9a276ea90b33370ddae8cabcab6349.png" />
      </div>
      <div className={`circle-one fr10 transition-right ${action === 'down' && 'down'} ${!isSmallScreen && pageTabActive > 0 && 'translate'}`}>
        <img src="https://oss.5rs.me/oss/uploadfe/png/3adea35885ce0d34c171938b26df76a3.png" />
      </div>
    </div>
  </div>;
}
