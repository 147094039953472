import FileSaver from 'file-saver';
import uuid from './uuid';
import * as Session from './session';
import crypto from './crypto';
import md5 from 'md5';

const Utils = {
  encrypt(data, key = '50525c3fbe3b9218') {
    return crypto.aesEncrypt(data, key);
  },
  decrypt(data, key = '50525c3fbe3b9218') {
    return crypto.aesDecrypt(data, key);
  },
  addHtmlNode({ elName, html, parent }) {
    let node = document.createElement(elName);
    node.innerHTML = html;
    parent.appendChild(node);
  },
  setRemUnit() {
    if(typeof window !== 'undefined') {
      let width = 1080;
      let height = 765;
      const setHtmlRoot = (fontSize) => {
        const width = this.getScreenWidth();
        if(width < 500) {
          document.documentElement.style.fontSize = 100 + 'px';
          return 100;
        } else if(width < 1366) {
          document.documentElement.style.fontSize = (width / 3.35) + 'px';
          return (width / 3.35);
        } else {
          document.documentElement.style.fontSize = fontSize + 'px';
          return fontSize;
        }
      };
      let docEI = window.document.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize',
        recalc = () => {
          let clientWidth = docEI.clientWidth < width ? width : docEI.clientWidth;
          let clientHeight = docEI.clientHeight < height ? height : docEI.clientHeight;
          clientWidth = width / (height / clientHeight);
          if(!clientWidth) return;
          //100是字体大小，1080是版心窗口的宽度，等比计算
          let fontSize = 100 * (clientWidth / width);
          if(window.devicePixelRatio === 1.5) {
            fontSize = fontSize * 0.80;
          }
          if(window.devicePixelRatio === 1.25) {
            fontSize = fontSize * 0.98;
          }
          return setHtmlRoot(fontSize);
        };
      if(!docEI.addEventListener) return;
      window.addEventListener(resizeEvt, recalc, false);
      window.document.addEventListener('DOMContentLoaded', recalc, false);
      return recalc();
    }
  },
  refreshRem() {
    (function(win) {
      function refreshRem() {
        // let designSize = 1220; // 设计图尺寸
        // let html = document.documentElement;
        // let wW = html.clientWidth < 1220 ? 1220 : html.clientWidth;// 窗口宽度
        // let rem = wW * 100 / designSize;
        document.documentElement.style.fontSize = 100 + 'px';
      }
      win.addEventListener('resize', function() {
        refreshRem();
      }, false);
      win.addEventListener('pageshow', function(e) {
        if(e.persisted) {
          refreshRem();
        }
      }, false);

      refreshRem();

    })(window);
  },
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },

  /**
   * unFlat Map 根据相应的字断
   * @param items 扁平数组
   * @param key 父类id key
   * @param parentId 父类id key
   * @param child 子类 key
   * @returns {[]}
   */
  unFlatMap(items, key = 'id', parentId = 'parentId', child = 'children') {
    const result = [];   // 存放结果集
    const itemMap = {};  //
    for(const item of items) {
      const id = item[key];
      const pid = item[parentId];

      if(!itemMap[id]) {
        itemMap[id] = { [child]: [] };
      }

      itemMap[id] = { ...item, [child]: itemMap[id][child] };

      const treeItem =  itemMap[id];

      if(pid === 0) {
        result.push(treeItem);
      } else {
        if(!itemMap[pid]) {
          itemMap[pid] = { [child]: [] };
        }
        itemMap[pid][child].push(treeItem);
      }
    }
    return result;
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },

  handlerOptions(option, textKey, valueKey) {
    if(!option) throw new Error('option can not be undifend or null');
    const optionProps = {};
    if(typeof option === 'object') {
      optionProps.disabled = option.disabled || false;
      option = {
        [textKey]: option[textKey],
        [valueKey]: option[valueKey]
      };
    } else {
      option = { [textKey]: option, [valueKey]: option };
    }
    return { option, optionProps };
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  },
  // 导出文件
  exportFile(url = '', name = '') {
    if(!url) return;
    return FileSaver.saveAs(url, `${`${name}`.trim()}.${Utils.getFileExt(url)}`);
  },

  isNotEmpty(value) {
    switch (typeof value) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if(Array.isArray(value)) {
          return value.length !== 0;
        } else if(value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  },

  //保留搜索页
  checkSearchOptions(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey));
    } else {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    }
  },

  //保留搜索页
  /**
   * 传了searchOptions就会存储，不传就是读取
   * @param searchOptions
   * @returns {any|void}
   */
  checkSearchOptions1(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(searchOptions) {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    } else if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey || '{}'));
    }
  },

  searchAssign(origin = {}, values = {}, ...params) {
    let tempValue = Object.assign({}, values);
    if(params && params.length) {
      params.forEach((item) => {
        Object.assign(tempValue, item);
      });
    }
    if(Object.keys(tempValue).length) {
      for(let key in tempValue) {
        if(this.isNotEmpty(tempValue[key]) && this.isNotEmpty(String(tempValue[key]))) {
          origin[key] = tempValue[key];
        } else {
          delete origin[key];
        }
      }
    } else {
      for(let key in origin) {
        if(!this.isNotEmpty(origin[key])) {
          delete origin[key];
        }
      }
    }
    return origin;
  },

  /**
   * 先过滤对象中的空属性，再合并，使用方法类似于Object.assign，不对第一个参数做修改，而是将结果作为新的对象返回
   * @param args
   * @returns {{}}
   */
  filterMerge(...args) {
    return Object.assign.apply(null, args.map((object) => {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        if(Utils.isNotEmpty(value) && value !== 'undefined') {
          obj[key] = value;
        }
        return obj;
      }, {});
    }));
  },

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  formatQuery2QueryStr(query = {}) {
    let queryStr = '';
    const filteredQuery = Utils.filterMerge(query);
    if(Utils.isNotEmpty(filteredQuery)) {
      queryStr = '?' + Utils.json2params(filteredQuery);
    }
    return queryStr;
  },

  /**
   * 链接拼参数
   * @param url 可以是带query的也可以是不带的
   * @param params 拼的参数对象
   * @returns {string}
   */
  padQuery(url = '', params = {}) {
    const [pathname, queryStr] = url.split('?');
    let tempQuery = Utils.filterMerge(Object.assign({}, Utils.params2json(queryStr), Utils.filterMerge(Object.assign({}, params))));
    let searchQuery = Utils.formatQuery2QueryStr(tempQuery);
    return `${pathname}${searchQuery}`;
  },
  // 权限管理处理数据信息
  handleDataInfo(data = [], type = 1) {
    let list = [];
    if(type === 1) {
      data.forEach(item => {
        const infoFirst = {};
        infoFirst.title = item.name;
        infoFirst.key = item.id;
        if(item.childList && item.childList.length > 0) {
          infoFirst.children = [];
          item.childList.forEach(v => {
            const infoTwo = {};
            infoTwo.title = v.name;
            infoTwo.key = v.id;
            infoFirst.children.push(infoTwo);
          });
        }
        list.push(infoFirst);
      });
    } else {
      data.forEach(item => {
        list.push(item.id);
        if(item.childList && item.childList.length > 0) {
          item.childList.forEach(v => {
            list.push(v.id);
          });
        }
      });
    }
    return list;
  },
  getDate(time) {
    if(time && !(+time > 0)) {
      let [tDate, tTime] = time.split(' ');
      tDate = tDate.replace(/[^\d]/g, '/');
      time = tTime ? `${tDate} ${tTime}` : `${tDate}`;
    }
    const d = time ? new Date(time) : new Date();
    const M = d.getMonth() + 1;
    const D = d.getDate();
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    return {
      Y: d.getFullYear(),
      M: M < 10 ? '0' + M : M,
      W: d.getDay(), //周几
      D: D < 10 ? '0' + D : D,
      h: h < 10 ? '0' + h : h,
      min: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
      ms: d.getMilliseconds()
    };
  },
  getSizeByByte(size) {
    if(typeof size !== 'number') {
      size = Number(size);
      // throw Error("Argument Must Be A Number")
    }
    const KBUNIT = 'KB', MBUNIT = 'MB', UNITSIZE = 1024;
    let kb = size / UNITSIZE,
      mb = size / (UNITSIZE * UNITSIZE);
    return mb > 0.01
      ? parseFloat(mb).toFixed(2) + MBUNIT
      : parseFloat(kb).toFixed(2) + KBUNIT;
    // return parseFloat(mb).toFixed(2) + MBUNIT;
  },
  /**
   * 三位数内的阿拉伯数字转换
   * @param num
   * @returns {string}
   */
  numberConvertToUppercase(num) {
    num = Number(num);
    let upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    let length = String(num).length;
    if(length === 1) {
      return upperCaseNumber[num];
    } else if(length === 2) {
      if(num === 10) {
        return upperCaseNumber[num];
      } else if(num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
      }
    }
  },
  getFileId(file = {}) {
    const ext = Utils.getFileExt(file.name);
    const lastModified = Number(new Date(file.lastModified || file.lastModifiedDate || ''));
    return md5(String(ext) + lastModified + file.size);
  },
  getScreenWidth() {
    const platform = this.getPlatform();
    if(window.screenDirection === 'vertical') {
      if(platform === 'android') {
        return screen.width;
      } else {
        return screen.width;
      }
    }
    if(window.screenDirection === 'horizontal') {
      if(platform === 'android') {
        return screen.width;
      } else {
        return screen.height;
      }
    }
  },
  getScreenHeight() {
    const platform = this.getPlatform();
    if(window.screenDirection === 'vertical') {
      return null;
    }
    if(window.screenDirection === 'horizontal') {
      if(platform === 'android') {
        return screen.height;
      } else {
        return screen.width;
      }
    }
  },
  isSmallScreen() {
    return this.getScreenWidth() < 1366;
  },
  getPlatform() {
    const u = navigator.userAgent;//userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值
    if(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) return 'android';
    if(u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) return 'ios';
  },
  setAnimationFrame(render, time) {
    //当前执行时间
    let nowTime = 0;
    //记录每次动画执行结束的时间
    let lastTime = Date.now();
    //我们自己定义的动画时间差值
    let diffTime = time;
    //当前requestAnimationFrame的id
    //此处使用对象，对象存储在地址空间，函数内部更新了对象的值，函数外部也可以接收到
    let timer = {};
    function animloop() {
      //记录当前时间
      nowTime = Date.now();
      if(nowTime - lastTime > diffTime) {
        lastTime = nowTime;
        render();
      }
      //timerid为数字
      timer.id = requestAnimationFrame(animloop);
    }
    animloop();
    return timer;
  },
  clearAnimationFrame(test) {
    cancelAnimationFrame(test.id);
  },
  isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  },
  createEncryption() {
    let uuidStr = uuid();
    uuidStr = uuidStr.replace(/-/g, 'h'); //去掉随机字符串里面的-
    let nonceStr = `${uuidStr}-${Date.now()}`;
    let md5Signature = md5(nonceStr + 'RAYS5.0yfzx');
    let signature = `${md5Signature}-${nonceStr}`;
    return {
      signature
    };
  }
};

export default Utils;

